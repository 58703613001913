export default defineNuxtRouteMiddleware((to, from) => {
  const authStore = useAuthStore()
  const device = useDevice()

  if (authStore.isAuthenticated) return

  if (device.isDesktop) {
    authStore.authModal = true
    return navigateTo(to.path === from.path ? '/' : from)
  }

  return navigateTo('/login')
})
